import $ from "jquery"
import currencies from "../../pages/_data/currencies"

$(() => {
  const $currencySelector = $(".currency-selector [data-currency]")

  $(".tier-card").find("a").on("click", (event) => {
    localStorage.setItem("tier", event.target.dataset.tier)
  })

  $currencySelector.on("click", (event) => {
    event.preventDefault()
    const currency = $(event.target).data("currency")
    const currencySign = currencies[currency].sign

    $currencySelector
      .attr("class", "link-primary")
      .attr("aria-pressed", "false")
    $(event.target)
      .attr("class", "link-dark")
      .attr("aria-pressed", "true")

    $("[data-pricing]").each((_i, el) => {
      const $pricingEl = $(el)
      const pricing = $pricingEl.data("pricing")

      $(".currency", el).html(currencySign)
      $(".number", el).html(pricing[currency])
    })
  })
})
