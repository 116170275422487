import $ from "jquery";

$(() => {
  const $technicalDetailsLink = $(".technical-details-link").find("a")
  const $technicalDetailsLists = $(".technical-container")

  $technicalDetailsLink.on("click", function (event) {
    const clickedIdSuffix = event.target.id.split("-").slice(-1)[0]

    $technicalDetailsLists.each((_i, e) => {
      if (e.id === `tier-${clickedIdSuffix}`) {
        e.style.removeProperty("display")
      } else {
        e.style.display = "none"
      }
    })
  })
})
