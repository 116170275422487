import ConsentManager from "@mainiotech/dataconsent-bootstrap";
import i18n from "@mainiotech/dataconsent-i18n";
import { services } from "@mainiotech/dataconsent-core";
import getServices from "@mainiotech/dataconsent-serviceregistry";

const initializeSettingsButtons = () => {
  const cm = document.documentElement.cm;

  document.querySelectorAll("button[data-dc-trigger='settings']").forEach((button) => {
    button.addEventListener("click", () => {
      cm.showSettings();
    });
  });
}

export default {
  init: () => {
    const locale = i18n.getLanguage();

    // Google Tags Manager integration
    // NOTE: This needs to happen before the CookieManager is created.
    if (window.gtag) {
      const consentState = (categories, key) => {
        if (categories.includes(key)) {
          return "granted";
        }

        return "denied";
      }

      // Update the GTM consent state accordingly to the data consent
      document.addEventListener("dataconsent", (ev) => {
        const acceptedCategories = ev.detail;

        window.gtag(
          "consent",
          "update",
          {
            "ad_storage": consentState(acceptedCategories, "marketing"),
            "analytics_storage": consentState(acceptedCategories, "analytics"),
          }
        );

        // This is to trigger tags at GTM when consent is given.
        window.dataLayer.push({ event: "data_consent" });
      });
    }

    const cm = new ConsentManager({
      categories: services(
        getServices(locale),
        i18n.getTranslations(),
        ["googleanalytics4", "googleads", "facebookpixel"]
      ),
    });
    document.documentElement.cm = cm;

    if (document.readyState === "complete" ||
      document.readyState === "loaded" ||
      document.readyState === "interactive"
    ) {
      initializeSettingsButtons();
    } else {
      document.addEventListener("DOMContentLoaded", initializeSettingsButtons);
    }

    return cm;
  },
};
