import "bootstrap"
import $ from "jquery"
import botDetector  from "./forms/bot-detector-lite";
import FormController  from "./forms/controller";
import "./front"
import "./topbar"
import "./modal"
import "./steps"
import "./order"
import consentManager from "./consent"

// Start the token generation for the user when they hit the site so that the
// token is ready when they want to submit forms. This keeps the bots less eager
// to try to spam us.
botDetector.generateToken();

consentManager.init();

$(() => {
  $("form[data-target]").each((_i, form) => {
    $(form).data("controller", new FormController(form))
  })
})
