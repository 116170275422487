import $ from "jquery"
import { createPopper } from "@popperjs/core";
import { ModuleFactory as bsMultiSelectFactory } from "@dashboardcode/bsmultiselect"

$(() => {
  if ($("#order-page-identifier").length >= 1 && localStorage.getItem("tier")) {
    $("#choose-application-tier")[0].value = window.localStorage.getItem("tier")
  }

  const multiSelectEnv = {window, createPopper}
  const multiSelect = bsMultiSelectFactory(multiSelectEnv).BsMultiSelect

  $("select[multiple]").each((_i, el) => {
    multiSelect(el)
  })
})
