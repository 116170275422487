import $ from "jquery"

$(() => {
  const checkScroll = (scrollY) => {
    const $header = $("header")
    const headerHeight = $header.outerHeight()
    const $navbar = $("nav");

    if (scrollY > headerHeight) {
      $header.addClass("scrolled")
      $navbar.removeClass("navbar-light")
      $navbar.addClass("navbar-dark")
    } else if (scrollY < headerHeight) {
      $header.removeClass("scrolled")
      $navbar.removeClass("navbar-dark")
      $navbar.addClass("navbar-light")
    }
  }

  document.addEventListener("scroll", () => {
    checkScroll(Math.round(window.scrollY))
  })

  checkScroll(Math.round(window.scrollY))
})
