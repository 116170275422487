import $ from "jquery";

$(() => {
  const $stepWrapper = $(".step-wrapper")
  const $stepButtons = $stepWrapper.find("button").add(".section-button")
  const $orderForm = $("#order-form")
  const $formSections = $orderForm.find(".form-section")

  const changeActiveStep = (element) => {
    const clickedId = element.id.split("-").slice(-1)[0]
    const $chosenSelection = $(`#section-${clickedId}`)
    const $clickedButton = $stepWrapper.find(`#button-${clickedId}`)

    $stepButtons.removeClass("active")
    $clickedButton.addClass("active")

    $chosenSelection.show()
    $chosenSelection.siblings().hide()
  }

  $stepButtons.on("click", (event) => {
    let element = event.target

    if (element.tagName === "SPAN") {
      element = element.parentNode
    }

    changeActiveStep(element)
    $(window).scrollTop(0);
  })

  $formSections.hide();
  $orderForm.find("#section-details").show();

  if ($("#order-page-identifier").length >= 1) {
    $stepWrapper.find("#button-details").addClass("active")
  }

  if ($("#confirmation-page-identifier").length >= 1) {
    $stepButtons.prop("disabled", true)
    $stepWrapper.find("#button-confirmation").addClass("active")
  } else {
    $stepWrapper.find("#button-confirmation").prop("disabled", true)
  }
})
